import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import QueLlevo from "./QueLlevo";
import Inventario from "./Inventario";
import QueLlevan from "./QueLlevan";

const router = createBrowserRouter([
  {
    path: "/",
    element: <QueLlevo />,
  },
  {
    path: "/inventario",
    element: <Inventario />,
  },
  {
    path: "/que-llevan",
    element: <QueLlevan />,
  },
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
