import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import logoPicnic from "./assets/logo-picnic.png";

interface Item {
  id?: number;
  name: string;
  quantity: number;
}

const Inventario = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<Item>({
    name: "",
    quantity: 0,
  });

  const fetchItems = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/items");
    const data = await response.json();
    setItems(data);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const addNew = async () => {
    if (!newItem.name || newItem.quantity <= 0) {
      Swal.fire({
        title: "¡Ups!",
        text: "Por favor ingresa un nombre y una cantidad válida",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/items", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newItem),
      });
      if (response.ok) {
        Swal.fire({
          title: "¡Gracias!",
          text: "Hemos añadido el nuevo item",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          setNewItem({
            name: "",
            quantity: 0,
          });
          fetchItems();
        });
      } else {
        Swal.fire({
          title: "¡Ups!",
          text: "Algo salió mal, por favor intenta de nuevo",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "¡Ups!",
        text: "Algo salió mal, por favor intenta de nuevo",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const deleteItem = async (id: number) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/items/" + id,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        Swal.fire({
          title: "¡Gracias!",
          text: "Hemos eliminado el item",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          fetchItems();
        });
      } else {
        Swal.fire({
          title: "¡Ups!",
          text: "Algo salió mal, por favor intenta de nuevo",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "¡Ups!",
        text: "Algo salió mal, por favor intenta de nuevo",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center pb-16">
      <div className="bg-[#0b4350] py-8 w-full flex justify-center">
        <img className="w-36" src={logoPicnic} alt="Logo Picnic 2024" />
      </div>
      <div className="flex flex-col w-96 pt-8">
        <h1 className="font-bold pb-8">Lista de cosas que necesitamos</h1>
        <ul>
          {items.map((item, index) => (
            <li key={index} className="flex flex-col gap-4 mb-2">
              <div className="flex items-center gap-4">
                <span className={item.quantity === 0 ? "line-through" : ""}>
                  - {item.quantity > 0 ? item.quantity : ""} {item.name}
                </span>
                <button
                  className="bg-red-600 text-white p-2 rounded-md"
                  onClick={() => {
                    deleteItem(item.id!);
                  }}
                >
                  Borrar
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col w-96 pt-8">
        <button
          className="bg-[#0b4350] text-white p-2 rounded-md mt-8 w-full"
          onClick={() => {
            setAddingNew(true);
          }}
        >
          Añadir nuevo
        </button>
      </div>
      {addingNew && (
        <div className="flex flex-col w-96 pt-8">
          <label>Nombre del nuevo item:</label>
          <input
            type="text"
            className="border border-gray-300 rounded-md p-2 w-full outline-none"
            value={newItem.name}
            onChange={(e) => {
              setNewItem({
                ...newItem,
                name: e.target.value,
              });
            }}
          />
          <label>Cantidad del nuevo item:</label>
          <input
            type="number"
            className="border border-gray-300 rounded-md p-2 w-full outline-none"
            value={newItem.quantity}
            onChange={(e) => {
              setNewItem({
                ...newItem,
                quantity: parseInt(e.target.value),
              });
            }}
          />
          <div className="flex gap-4 pt-8">
            <button
              className="bg-[#0b4350] text-white p-2 rounded-md w-full"
              onClick={() => {
                setAddingNew(false);
                addNew();
              }}
            >
              Añadir
            </button>
            <button
              className="bg-[#0b4350] text-white p-2 rounded-md w-full"
              onClick={() => {
                setAddingNew(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Inventario;
