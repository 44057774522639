import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import logoPicnic from "./assets/logo-picnic.png";

interface Item {
  id: number;
  name: string;
  quantity: number;
}

const QueLlevo = () => {
  const [familyName, setFamilyName] = useState<string>("");
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);

  const fetchItems = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/missing");
    const data = await response.json();
    setItems(data);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const clear = async () => {
    setFamilyName("");
    setSelectedItems([]);
    await fetchItems();
  };

  const submit = async () => {
    if (!familyName) {
      Swal.fire({
        title: "¡Ups!",
        text: "Por favor ingresa el nombre de tu familia",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!selectedItems.length) {
      Swal.fire({
        title: "¡Ups!",
        text: "Por favor selecciona al menos una opción",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (selectedItems.length > 1) {
      Swal.fire({
        title: "¡Ups!",
        text: "Por favor selecciona solo una opción",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/contributions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            familyName,
            items: selectedItems,
          }),
        }
      );
      if (response.ok) {
        Swal.fire({
          title: "¡Gracias!",
          text: "Hemos recibido tu confirmación",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          clear();
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "¡Ups!",
          text: "Algo salió mal, por favor intenta de nuevo",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "¡Ups!",
        text: "Algo salió mal, por favor intenta de nuevo",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center pb-16">
      <div className="bg-[#0b4350] py-8 w-full flex justify-center">
        <img className="w-36" src={logoPicnic} alt="Logo Picnic 2024" />
      </div>
      <div className="flex flex-col w-96 pt-4 gap-4">
        <p>
          Hemos organizado un menú para compartir que consiste en un vigorón
          para los adultos y perros calientes para los niños!
        </p>
        <p>
          Para ello tenemos una cuota de ₡2.000 por adulto y adicional
          quisieramos solicitarte la colaboración seleccionando{" "}
          <strong>una</strong> de las siguientes opciones por familia para
          contruibuir al compartir del PICNIC!
        </p>
        <p>
          La cuota pueden pagarla al sinpemovil 7015-5104 a nombre de Daniel
          Calvo Marín
        </p>
        <p>Nos vemos!! ✨</p>
        <hr />
      </div>
      <div className="flex flex-col w-96 pt-4">
        <label>Nombre de la familia</label>
        <input
          className="border border-gray-300 rounded-md p-2 w-full outline-none"
          type="text"
          placeholder="Nombre de la familia"
          value={familyName}
          onChange={(e) => setFamilyName(e.target.value)}
        />
      </div>
      <div className="flex flex-col w-96 pt-8">
        <h1 className="font-bold pb-8">Opciones de cosas con las que nos pueden contribuir:</h1>
        <ul>
          {items.map((item, index) => (
            <li key={index} className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <input
                  disabled={item.quantity === 0}
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedItems([...selectedItems, item]);
                    } else {
                      setSelectedItems(
                        selectedItems.filter(
                          (selectedItem) => selectedItem.name !== item.name
                        )
                      );
                    }
                  }}
                />
                <span className={item.quantity === 0 ? "line-through" : ""}>
                  {item.quantity > 0 ? item.quantity : ""} {item.name}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col w-96 pt-8">
        <button
          className="bg-[#0b4350] text-white p-2 rounded-md mt-8 w-full"
          onClick={submit}
        >
          Enviar confirmación
        </button>
      </div>
    </div>
  );
};

export default QueLlevo;
