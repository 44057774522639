import { useEffect, useState } from "react";
import logoPicnic from "./assets/logo-picnic.png";

interface Contribution {
  id: number;
  name: string;
  item: string;
  quantity: number;
}

const QueLlevan = () => {
  const [contibutions, setContributions] = useState<Contribution[]>([]);

  const fetchItems = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/contributions");
    const data = await response.json();
    setContributions(data);
  };

  useEffect(() => {
    fetchItems();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center pb-16">
      <div className="bg-[#0b4350] py-8 w-full flex justify-center">
        <img className="w-36" src={logoPicnic} alt="Logo Picnic 2024" />
      </div>
      <div className="flex flex-col w-96 pt-8">
        <h1 className="font-bold pb-8">Contribuciones</h1>
        <ul>
          {contibutions.map((contibution, index) => (
            <li key={index} className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <span>
                  La familia {contibution.name} llevará {contibution.quantity} {contibution.item}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QueLlevan;
